import React, { useCallback, useEffect, useMemo } from 'react'
import './style.css'
import countries from '../datajson/Countries.json'
import states from '../datajson/States.json'
import currencies from '../datajson/Currency.json'
import Pricebreakupind from '../Components/Pricebreakupind'
import Pricebreakupnor from '../Components/Pricebreakupnor'
import Modal from 'react-bootstrap/Modal'; 
import Button from 'react-bootstrap/Button'; 
import Tableheadingind from '../Components/Tableheadingind'
import Tableheadingnormal from '../Components/Tableheadingnormal'
import { useState } from 'react'
import $ from "jquery";
import Swal from 'sweetalert2'
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";

import "react-country-state-city/dist/react-country-state-city.css";

import { motion } from "framer-motion";
import { TypeAnimation } from 'react-type-animation';
import{ useRef } from 'react';
import { useReactToPrint } from 'react-to-print';





function Invoice() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [invoicenum,setInvoicenum] = useState("");
  const [invoicedate,setInvoicedate] = useState("");
  const [invoicedue,setInvoicedue] = useState("");
  const [logo,setLogo] = useState("");
  const [currency,setCurrency] = useState("");
  const [billedfrom,setBilledform] = useState(0);
  const [billedto,setBilledto] = useState("");
  const [businessname,setBusinessname] = useState("");
  const [customer,setCustomer] = useState("");
  const [city,setCity] = useState("");
  const [tocity,setTocity] = useState("");
  const [state,setstate] = useState(0);
  const [tostate,setTostate] = useState("");
  const [zipcode,setzipcode] = useState("");
  const [tozipcode,setTozipcode] = useState("");
  const [phone,setPhone] = useState("");
  const [tophone,setTophone] = useState("");
  const [email,setEmail] = useState("");
  const [toemail,setToemail] = useState("");
  const [mygst,setMygst] = useState("");
  const [custgst,setCustgst] = useState("");
  const [items, setItems] = useState([]);
  const [indianitems, setIndianItems] = useState([]);
  const [gstItems,setGstitems] = useState(false);
  const[discallowed,setDiscallowed] = useState("");
  const[terms,setTerms] = useState("");
  const[payterms,setPay] = useState("");
  const [disptab,setDisptab]=useState(true);
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [mystate,setMystate]= useState(true);
  const [countriesList,setCountrieslist] = useState([]);

  let arrcountry = []

  

  // useEffect(()=>{
  //   getCountries()
  // },billedfrom)


  useEffect(() => {
    GetCountries().then((result) => {
      setCountrieslist(result);
      });
    },billedfrom);
  

    //important functions


  const addtabs = () =>{
   if(billedfrom === 101){
    $('.tableheadone').hide()
    $('.tableheadtwo').show()
    $('.tab_body').hide()
    $('.tab_bodytwo').show()
    $('#gstrow').show()
    $('#bygst').show()
    $('#togst').show()
   
    }else{
      $('.tableheadone').show()
      $('.tableheadtwo').hide()
      $('.tab_body').show()
      $('.tab_bodytwo').hide()
      $('#gstrow').hide()
      $('#bygst').hide()
      $('#togst').hide()
     
   }
  };

  useEffect(() => {
    if (billedfrom === 101) {
      setIndianItems([...indianitems]);
    } else {
      setItems([...items]);
    }
  }, [billedfrom]);
  
  const addLineNormal = () => {
      setItems([
        ...items,
        { item: '', quantity: '', rate: '', amount: '' }
      ]);
  };

  const addLineIndia = () =>{
    setIndianItems([
      ...indianitems,
      { item: '', quantity: '', rate: '', gst: '', taxableamount: '', sgst: '',cgst: '',amount: ''}
    ]);
  }

  const addLine = () =>{
    if(billedfrom == 101){
      addLineIndia()

    }else{
      if(billedfrom != 101){
        addLineNormal()
      }
      
    }
  }

  const handleInputChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };
  const handleInputChange2 = (index, field, value) => {
    const newItems = [...indianitems];
    newItems[index][field] = value;
    setIndianItems(newItems);
  };
  const removeLastLine = () => {
    if (items.length > 0) {
      setItems(items.slice(0, -1));
    }
  };

  const removeLastLineIndia = () => {
    if (indianitems.length > 0) {
      setIndianItems(indianitems.slice(0, -1));
    }
  };

  const linedelete =()=>{
    if(billedfrom === 101){
      removeLastLineIndia()
      
    }else{
      removeLastLine()
      
    }
  }
  

  const removeLineind = (index) => {
    const newItemsInd = indianitems.filter((_, i) => i !== index);
    setIndianItems(newItemsInd);
  };
  const removeLine = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };
  
  const tablefront = ()=>{
    if(billedfrom === 101){
      return <Tableheadingind inditms={indianitems} />
      
    }else{
      if(billedfrom != 101){
        return <Tableheadingnormal item={items}/>
      }
      
    }
  }

  const switchstat = () =>{
    if(billedfrom){
      setDisptab(false)
      setMystate(false)
      Swal.fire({
        title: "Billing Country Selected",
        text: gettoCountries() + "  Billing Country Locked !",
        icon: "success"
      });
    }else {
      if(!billedfrom){
        Swal.fire({
          title: "Hi There User !",
          text: "Please Select A Billing Country To Begin...",
          icon: "warning"
        });
        setDisptab(true)
      }
    }

  }

  
  
  const totalbill = ()=>{
    if(billedfrom === 101){
      let elems = []
      let tax = []
      let sg = []
      let cg = []
      {
        indianitems.map((i)=>{
          elems.push(i.amount);
        })
      }
      {
        indianitems.map((t)=>{
          tax.push(t.gst);
        })
      }
      {
        indianitems.map((st)=>{
          sg.push(st.sgst);
        })
      }
      {
        indianitems.map((ct)=>{
          cg.push(ct.cgst);
        })
      }
      // console.log(indianitems[0].sgst)
      return <Pricebreakupind curr1={currency} amtind={elems} dscind={discallowed} taxes={tax} stategst={sg} cengst={cg} />
    }else{
      
      let emp = []
      {
         items.map((i)=>(
           emp.push(i.amount)
        ))
      }
      
      return<Pricebreakupnor curr2={currency} amt={emp} dsc={discallowed}/>
      
    }
    
  }
 

  const calculations = ()=>{
    if(billedfrom === 101){
      indianitems.map((m)=>{
        if(isNaN(m.amount)){
          m.amount = 0
        }else{
          let taxed = m.quantity *m.rate
          m.taxableamount = taxed
          let gstcal = taxed * m.gst / 100 + taxed
          m.amount = gstcal
        }
       
        // let div = m.gst / 2
        // m.cgst = div + "%"
        // m.sgst = div + "%"
      })
    }else{
      items.map((i)=>{
        if (isNaN(i.amount)){
        i.amount = 0
      
        }else{
          let amnt = i.quantity * i.rate
          i.amount = amnt
        }
        
        
      })
    }
  }

  calculations()

  const muteItems =()=>{
    if(billedfrom === 101){
      setGstitems(true);
    }else{
      setGstitems(false);
    }
  }
  
  useEffect(()=>{
    addtabs()
    muteItems()
    addtabs()
    switchstat()
  },[billedfrom]);
  
  const gettoCountries = () =>{
    countriesList.map((items)=>{
      arrcountry.push(items.name)
    })
    if (billedfrom <=99){
      return arrcountry[billedfrom - 1]
    }else if(billedfrom > 99 && billedfrom < 199){
      return arrcountry[billedfrom]
    }else {
      return arrcountry[billedfrom +1]
    }
  }
  useEffect(()=>{
    gettoCountries()
  },[billedfrom])
  
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });
 


  return (
    <div
      className="fullbody"
      style={{
        backgroundImage: `url(${require("../images/cyber.png")})`,
        backgroundSize: "cover",
        height: "auto",
        width: "100vw",
      }}
    >
      <div className="container-fluid" style={{ height: "10vh" }}>
        <TypeAnimation id="type"
          sequence={[
            // Same substring at the start will only be typed once, initially
            "Generate Invoice For Your Business",
            1000,
            "Generate Invoice For Your Clients",
            1000,
            "Generate Invoice For Your Customers",
            1000
          ]}
          speed={50}
          style={{ fontSize: "1.5rem" , color: "white", fontWeight:"bold"}}
          repeat={Infinity}
        />
      </div>
      <motion.div
        animate={{ x: [null, 100, 0] }}
        className="d-flex justify-content-center"
      >
        <div className="parent-form mt-2 " style={{ backgroundColor: "white" }}>
          <div className="form-body">
            <form className="row g-3 text-start">
              <div className="col-md-6">
                <label for="Invoiceno" className="form-label ">
                  Invoice No
                </label>
                <input
                  type="email"
                  name="invoicenum"
                  className="form-control"
                  id="Invoiceno"
                  value={invoicenum}
                  onChange={(e) => setInvoicenum(e.target.value)}
                />
                <label for="invoicedate" className="form-label">
                  Invoice Date
                </label>
                <input
                  type="date"
                  name="invoicedate"
                  className="form-control"
                  id="invoicedate"
                  value={invoicedate}
                  onChange={(e) => setInvoicedate(e.target.value)}
                />
                <label for="invoicedue" className="form-label">
                  Due Date
                </label>
                <input
                  type="date"
                  name="invoicedue"
                  className="form-control"
                  id="invoicedue"
                  value={invoicedue}
                  onChange={(e) => setInvoicedue(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label for="currency" className="form-label">
                  Currency
                </label>
                <select
                  id="currency"
                  name="curr"
                  className="form-select"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option selected>Choose...</option>
                  {currencies.map((items) => (
                    <option>
                      {items.name} {items.symbol}
                    </option>
                  ))}
                </select>
                <label for="logo" className="form-label">
                  Logo
                </label>
                <input
                  type="file"
                  id="logo"
                  name="logo"
                  className="form-control"
                  onChange={(e) =>
                    setLogo(URL.createObjectURL(e.target.files[0]))
                  }
                />
                <label for="terms" className="form-label">
                  {" "}
                  Payment Terms
                </label>
                <input
                  type="text"
                  id="payterms"
                  value={payterms}
                  onChange={(e) => setPay(e.target.value)}
                  name="payment_terms"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label for="BilledBy" className="form-label">
                  Billing Country
                </label>
                {/* <select
                  id="BilledBy"
                  name="billedby"
                  className="form-select"
                  value={billedfrom}
                  onChange={(e) => setBilledform(e.target.value)}
                >
                  <option selected>Choose Country..</option>
                  {countries.map((items) => (
                    <option>{items.name}</option>
                  ))}
                </select> */}
                <CountrySelect
                  onChange={(e) => {
                    setBilledform(e.id);
                  }}
                  placeHolder="Select Country"
                />
                <label for="BusinessName" className="form-label">
                  Business Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="BusinessName"
                  name="businessname"
                  placeholder="Your Business Name"
                  value={businessname}
                  onChange={(e) => setBusinessname(e.target.value)}
                />
              </div>

              <div className="col-md-6">
                <label for="BilledTo" className="form-label">
                  Billed To Country
                </label>
                <CountrySelect
                  onChange={(e) => {
                    setCountryid(e.id);
                  }}
                  placeHolder="Select Country"
                />
                <label for="customername" className="form-label">
                  Business / Customer Name
                </label>
                <input
                  type="text"
                  name="cust"
                  className="form-control"
                  id="customername"
                  value={customer}
                  onChange={(e) => setCustomer(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label for="citycust" className="form-label">
                  Your Address :
                </label>
                <input
                  type="text"
                  name="custcity"
                  className="form-control"
                  id="citycust"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <label for="custState" className="form-label">
                  State
                </label>
                <StateSelect
                  disabled={mystate}
                  countryid={billedfrom}
                  onChange={(e) => {
                    setTostate(e.id);
                  }}
                  placeHolder="Select State"
                />
                <label for="Emailcust" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  name="custmail"
                  className="form-control"
                  id="Emailcust"
                  value={toemail}
                  onChange={(e) => setToemail(e.target.value)}
                />
                <label for="PhoneNumbercust" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="custphone"
                  className="form-control"
                  id="inputCity"
                  value={tophone}
                  onChange={(e) => setTophone(e.target.value)}
                />

                <label for="zipcust" className="form-label">
                  Zip Code
                </label>
                <input
                  type="text"
                  name="custzip"
                  className="form-control"
                  id="inputCity"
                  value={tozipcode}
                  onChange={(e) => setTozipcode(e.target.value)}
                />
              </div>
              <div className="col-md-6 ">
                <label for="myCity" className="form-label">
                  Client's Address :
                </label>
                <input
                  type="text"
                  name="mycity"
                  className="form-control"
                  id="myCity"
                  value={tocity}
                  onChange={(e) => setTocity(e.target.value)}
                />
                <label for="myState" className="form-label">
                  State
                </label>
                {/* <select id="myState" name="mystate" className="form-select" value={state} onChange={(e)=>setstate(e.target.value)}>
                <option selected>Choose...</option>
                {states.map((codes) => (
                  <option>{codes.Name}</option>
                ))}
              </select> */}
                <StateSelect
                  countryid={countryid}
                  onChange={(e) => {
                    setstateid(e.id);
                  }}
                  placeHolder="Select State"
                />
                <label for="mymail" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  name="myemail"
                  className="form-control"
                  id="mymail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label for="mynum" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="myphone"
                  className="form-control"
                  id="mynum"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />

                <label for="myzip" className="form-label">
                  Zip Code
                </label>
                <input
                  type="text"
                  name="myzip"
                  className="form-control"
                  id="myzip"
                  value={zipcode}
                  onChange={(e) => setzipcode(e.target.value)}
                />
              </div>
              <div className="row" id="gstrow">
                <div className="col-md-4">
                  <label for="gst" className="form-label">
                    GST Details Sender :
                  </label>
                  <input
                    type="text"
                    name="mygst"
                    className="form-control"
                    id="mygst"
                    value={mygst}
                    onChange={(e) => setMygst(e.target.value)}
                  />
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <label for="gst" className="form-label">
                    GST Details Customer :
                  </label>
                  <input
                    type="text"
                    name="custgst"
                    className="form-control"
                    id="custgst"
                    value={custgst}
                    onChange={(e) => setCustgst(e.target.value)}
                  />
                </div>
              </div>
              <label className="form-label">Enter Items</label>
              <div className="table-responsive">
                <table className="table table-bordered border-primary">
                  <thead className="tableheadone">
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Item</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <thead className="tableheadtwo">
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Item</th>
                      <th scope="col">Quantity</th>
                      <th className="rates" scope="col">
                        Rate
                      </th>
                      <th scope="col">GST</th>
                      <th className="amounts" scope="col">
                        Taxable Amount
                      </th>
                      <th scope="col">SGST</th>
                      <th scope="col">CGST</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={index} className="tablerows">
                        <th scope="row">{index + 1}</th>
                        <td>
                          <textarea
                            class="text-break"
                            type="text"
                            name="item"
                            size="5"
                            className="form-control input-lg"
                            placeholder="Item"
                            value={item.item}
                            style={{
                              height: "80px",
                              width: "300px",
                              padding: "5px !important",
                            }}
                            onChange={(e) =>
                              handleInputChange(index, "item", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="quantity"
                            className="form-control"
                            placeholder="Quantity"
                            value={item.quantity}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "quantity",
                                e.target.value
                              )
                            }
                            disabled={disptab}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="rate"
                            className="form-control"
                            placeholder="Rate"
                            value={item.rate}
                            onChange={(e) =>
                              handleInputChange(index, "rate", e.target.value)
                            }
                            disabled={disptab}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="amount"
                            className="form-control"
                            placeholder="Amount"
                            value={item.amount}
                            onChange={(e) =>
                              handleInputChange(index, "amount", e.target.value)
                            }
                            disabled={disptab}
                          />
                        </td>
                      </tr>
                    ))}
                    {indianitems.map((newits, index) => (
                      <tr key={index} className="tablerows">
                        <th scope="row">{index + 1}</th>
                        <td>
                          <textarea
                            type="text"
                            name="item"
                            className="form-control form-control-lg"
                            placeholder="Item"
                            value={newits.item}
                            style={{
                              height: "80px",
                              width: "300px",
                              padding: "5px !important",
                            }}
                            onChange={(e) =>
                              handleInputChange2(index, "item", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="quantity"
                            className="form-control"
                            placeholder="Quantity"
                            value={newits.quantity}
                            style={{
                              height: "40px",
                              width: "90px",
                              padding: "5px !important",
                            }}
                            onChange={(e) =>
                              handleInputChange2(
                                index,
                                "quantity",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="rate"
                            className="form-control"
                            placeholder="Rate"
                            value={newits.rate}
                            style={{
                              height: "40px",
                              width: "90px",
                              padding: "5px !important",
                            }}
                            onChange={(e) =>
                              handleInputChange2(index, "rate", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="amount"
                            className="form-control"
                            placeholder="GST %"
                            value={newits.gst}
                            style={{
                              height: "40px",
                              width: "90px",
                              padding: "5px !important",
                            }}
                            onChange={(e) =>
                              handleInputChange2(index, "gst", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="amount"
                            className="form-control"
                            placeholder="Taxable Amount"
                            value={newits.taxableamount}
                            style={{
                              height: "40px",
                              width: "90px",
                              padding: "5px !important",
                            }}
                            onChange={(e) =>
                              handleInputChange2(
                                index,
                                "taxableamount",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="amount"
                            className="form-control"
                            placeholder="SGST"
                            value={newits.sgst}
                            style={{
                              height: "40px",
                              width: "90px",
                              padding: "5px !important",
                            }}
                            onChange={(e) =>
                              handleInputChange2(index, "sgst", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="amount"
                            className="form-control"
                            placeholder="CGST"
                            value={newits.cgst}
                            style={{
                              height: "40px",
                              width: "90px",
                              padding: "5px !important",
                            }}
                            onChange={(e) =>
                              handleInputChange2(index, "cgst", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="amount"
                            className="form-control"
                            placeholder="Amount"
                            value={newits.amount}
                            style={{
                              height: "40px",
                              width: "90px",
                              padding: "5px !important",
                            }}
                            onChange={(e) =>
                              handleInputChange2(
                                index,
                                "amount",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-6 h-40">
                  <label className="form-label">Discount(If Any)</label>
                  <input
                    style={{ width: "120px" }}
                    type="text"
                    id="disc"
                    name="discount_allowed"
                    value={discallowed}
                    onChange={(e) => setDiscallowed(e.target.value)}
                    className="form-control"
                    placeholder="Discount %"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 h-40">
                  <label for="conditions" className="form-label">
                    Terms & Conditions
                  </label>
                  <textarea
                    type="text"
                    id="condi"
                    name="payment_conditions"
                    className="form-control"
                    value={terms}
                    onChange={(e) => setTerms(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-2">
                <button
                  id="add_itm_but"
                  type="button"
                  className="btn btn-primary btn-rounded"
                  disabled={disptab}
                  onClick={addLine}
                >
                  Add Items
                </button>
              </div>
              <div className="col-2">
                <button
                  id="rmv_itm_but"
                  type="button"
                  className="btn btn-primary btn-rounded"
                  onClick={linedelete}
                >
                  Remove Items
                </button>
              </div>
              <div className="col-md-3">
                {/* <button type="button" className="btn btn-primary">
                Preview Invoice
              </button> */}
                <Button id="prevbut" variant="primary" onClick={handleShow}>
                  Preview Invoice
                </Button>
              </div>
            </form>
          </div>
         <div style={{height:"20vh"}}></div>
          <Modal className="modal-xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Your Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body  ref={contentToPrint} style={{padding: "20px"}}>
              <div>
                <div className="row">
                  <div className="col-10">
                    <h3>Invoice No. {invoicenum}</h3>
                    <p>Invoice Date : {invoicedate}</p>
                    <p>Invoice Due Date : {invoicedue}</p>
                  </div>
                  <div className="col-2 ">
                    <img class="img-fluid" id="logoset" src={logo} />
                  </div>
                </div>
              </div>
              <div className="row p-1 billed">
                <div className="col-5 bby mt-1 p-8">
                  <h6>Billed By</h6>
                  <p>{businessname}</p>
                  <p>Address :  {city}</p>
                  <p>Zipcode :  {tozipcode}</p>
                  <p>Phone :  {tophone}</p>
                  <p>Email :  {toemail}</p>
                  <p
                    className="gstbilled"
                    style={{ display: gstItems ? "block" : "none" }}
                  >
                    {mygst}
                  </p>
                </div>
                <div className="col-2"></div>
                <div className="col-5 bto mt-1 ">
                  <h6>Billed To</h6>
                  <p>{customer}</p>
                  <p>Address :  {tocity}</p>
                  <p>Zipcode :  {zipcode}</p>
                  <p>Phone :  {phone}</p>
                  <p>Emai :  {email}</p>
                  <p style={{ display: gstItems ? "block" : "none" }}>
                    {custgst}
                  </p>
                </div>
                <div className=" mt-5">
                  {/* <table class="table table-dark"> */}
                  {tablefront()}
                </div>
                <div className="row mt-4">
                  <div className="col-4">
                    <h4>Payment Details :</h4>
                    <p>Payment Terms : {payterms}</p>
                  </div>
                </div>
                <div className="col-6"></div>
                <div className="col-4 ">
                  <h4 className="bill">Subtotal :</h4>
                  <h4 className="bill">Discount :</h4>
                  <h4 className="bill">Taxable Amount :</h4>
                  <h4
                    className="bill"
                    style={{ display: gstItems ? "block" : "none" }}
                  >
                    SGST :{" "}
                  </h4>
                  <h4
                    className="bill"
                    style={{ display: gstItems ? "block" : "none" }}
                  >
                    CGST :{" "}
                  </h4>
                  <hr></hr>
                  <h3 className="bill">Total : </h3>
                </div>
                {totalbill()}
                <div className="row">
                  <div className="col-5">
                    <h3>Terms & Conditions</h3>
                    <ul>
                      <li className="termscondi">
                        <p>{terms}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => {
        handlePrint(null, () => contentToPrint.current);
      }} >
                Print
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Back To Edit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </motion.div>
    </div>
  );
}

export default Invoice