import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { useState } from 'react';

import Invoice from './Pages/Invoice';

function App() {
  
  return (
    <div className="App">
      <Invoice/>
    </div>
  );
}

export default App;
