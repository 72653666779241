import React from 'react'

function Pricebreakupind(props) {
  const splitter1 = ()=>{
    let temp = props.curr1.split(" ");
    return temp[2];
  };

  const finalbill =()=>{
    let arr =  props.amtind;
    let sum = 0
    for(let i = 0; i < arr.length; i ++){
      sum += arr[i]
    }
    return sum
  }
  const gst_calculator = ()=>{
    let mul_gst = props.taxes
    let all_gst = []
    let sum_gst = 0
    mul_gst.map((g)=>{
      let temp = (finalbill() * g)/100
      all_gst.push(temp)
    })
    for(let s = 0 ; s < all_gst.length; s ++){
      sum_gst += all_gst[s]
    }
    return sum_gst

  }

  const main_discount =()=>{
    let disc = props.dscind
    let dscallowed = finalbill() * disc / 100
    return dscallowed
  }
  const taxable_amt = ()=>{
    let finbill = finalbill() - main_discount()
    return finbill
  }
  const main_bill = () =>{
    return Math.ceil(taxable_amt() - gst_calculator())
  }
  const stategst = ()=>{
    let sttgst = props.stategst
    let fullamt = props.amtind
    let stg = []
    let sum1 = 0
    let sums = 0
    for(let n = 0; n < sttgst.length; n++){
      for(let p = 0; p < fullamt.length; p++){
        let temp = (fullamt[p] * sttgst[n])/100
        sum1 += temp
        stg.push(sum1)
      }
    }
    for ( let l = 0; l < stg.length; l++){
      sums += stg[l]
    }
    return Math.ceil(sums);
  }
  const csgst = ()=>{
    let cttgst = props.cengst
    let fullamt = props.amtind
    let ctg = []
    let sum2 = 0
    let sumc = 0
    for(let n = 0; n < cttgst.length; n++){
      for(let p = 0; p < fullamt.length; p++){
        let temp = (fullamt[p] * cttgst[n])/100
        sum2 += temp
        ctg.push(sum2)
      }
    }
    for ( let l = 0; l < ctg.length; l++){
      sumc += ctg[l]
    }
    return Math.ceil(sumc);
  }

  
 
  return (
    <div className="col-2 ">
      <h4 className="billnum"> {finalbill()} {splitter1()}</h4>
      <h4 className="billnum"> {main_discount()} {splitter1()}</h4>
      <h4 className="billnum"> {taxable_amt()} {splitter1()}</h4>
      <h4 className="billnum">  {stategst()} {splitter1()}</h4>
      <h4 className="billnum">  {csgst()} {splitter1()}</h4>
      <hr></hr>
      <h4 className="billnum"> {main_bill()} {splitter1()}</h4>
    </div>
  );
}

export default Pricebreakupind