import React from 'react'
import bootstrapMin from 'bootstrap/dist/js/bootstrap.min';


function Tableheadingind(props) {
  
  return (
    <div className='table-responsive'>
    <table className="table table-dark" id="ind">
    <thead>

      <tr>
        <th scope="col">No.</th>
        <th scope="col">Item Description</th>
        <th scope="col">Qty</th>
        <th scope="col">Rate</th>
        <th scope="col">GST</th>
        <th scope="col">Taxable Amount</th>
        <th scope="col">SGST</th>
        <th scope="col">CGST</th>
        <th scope="col">Amount</th>
      </tr>
    </thead>
    <tbody>
      {props.inditms.map((indianlines)=>(
        <tr>
        <th scope="row">1</th>
        <td>{indianlines.item}</td>
        <td>{indianlines.quantity}</td>
        <td>{indianlines.rate}</td>
        <td>{indianlines.gst}</td>
        <td>{indianlines.taxableamount}</td>
        <td>{indianlines.sgst}</td>
        <td>{indianlines.cgst}</td>
        <td>{indianlines.amount}</td>
      </tr>

      ))}
    
  </tbody>
</table>
</div>
  )
}

export default Tableheadingind