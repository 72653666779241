import React from 'react'
import Invoice from '../Pages/Invoice';


function Tableheadingnormal(props) {
  return (
    <table class="table table-dark table-lg" id='normal'>
    <thead>
      <tr>
        <th scope="col">No</th>
        <th scope="col">Item Description</th>
        <th scope="col">Quantity</th>
        <th scope="col">Rate</th>
        <th scope="col">Amount</th>
      </tr>
    </thead>
    <tbody>
     {props.item.map((lines)=>(
       <tr>
       <th scope="row">1</th>
       <td>{lines.item}</td>
       <td>{lines.quantity}</td>
       <td>{lines.rate}</td>
       <td>{lines.amount}</td>
     </tr>
     ))}
        
   
  </tbody>
</table>
  );
}

export default Tableheadingnormal