import React from 'react'

function Pricebreakupnor(props) {
  const splitter1 = ()=>{
    let temp = props.curr2.split(" ");
    return temp[2];
  };
  const finalbill =()=>{
    let arr =  props.amt;
    let sum = 0
    for(let i = 0; i < arr.length; i ++){
      sum += arr[i]
    }
    return sum
  }
  const main_discount =()=>{
    let disc = props.dsc
    let dscallowed = finalbill() * disc / 100
    return dscallowed
  }
  const dsc_calculator = ()=>{
    let finbill = finalbill() - main_discount()
    return finbill
  }

 
  
  return (
    <div className="col-2 ">
      <h4 className="billnum">{finalbill()}{splitter1()}</h4>
      <h4 className="billnum"> {main_discount()} {splitter1()}</h4>
      <hr></hr>
      <h4 className="billnum"> {dsc_calculator()} {splitter1()}</h4>
    </div>
  )
}

export default Pricebreakupnor;